/*
=========================================================
* White Stone Gayrimenkul - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// White Stone Gayrimenkulenkul components
import MKBox from "components/MKBox";
import MKSocialButton from "components/MKSocialButton";
import MKTypography from "components/MKTypography";

// White Stone Gayrimenkulenkul examples
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Presentation page sections

// Presentation page components

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

// Images

function Presentation() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />

      <MKBox pt={18} pb={6}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
              <MKTypography variant="body1" color="text">
                White Stone Gayrimenkul, hayalinizdeki evi bulmanızı sağlayan güvenilir gayrimenkul
                danışmanınız.{" "}
              </MKTypography>
              <MKTypography variant="body1" color="text">
                Sayfamız yapım aşamasındadır. İlanlar sayfamızla yakında hizmetinizdeyiz...
              </MKTypography>
            </Grid>
            <Grid
              item
              xs={12}
              lg={5}
              my={{ xs: 5, lg: "auto" }}
              mr={{ xs: 0, lg: "auto" }}
              sx={{ textAlign: { xs: "center", lg: "right" } }}
            >
              <MKSocialButton
                component="a"
                href="https://www.instagram.com/whitestonegayrimenkul/"
                target="_blank"
                color="instagram"
                sx={{ mr: 1 }}
              >
                <i className="fab fa-instagram" />
                &nbsp;Instagram
              </MKSocialButton>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
