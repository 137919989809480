// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

// White Stone Gayrimenkul components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/whitestonelogo_simple.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "White Stone Gayrimenkul",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/white.stone.gayrimenkul",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/whitestonegayrimenkul/",
    },
  ],
  menus: [],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} by{" "}
      <MKTypography
        component="a"
        href="https://www.linkedin.com/in/ilknur-y%C4%B1ld%C4%B1r%C4%B1m-1b0b9794/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        İlknur Yıldırım
      </MKTypography>
      .
    </MKTypography>
  ),
};
