/* eslint-disable react/no-unescaped-entities */
/*
=========================================================
* White Stone Gayrimenkul - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// White Stone Gayrimenkulenkul components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

function AboutUs() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <MKTypography variant="h3" my={1}>
              Hakkımızda
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              White Stone Gayrimenkul, gayrimenkul dünyasındaki serüveninizde güvenilir bir rehber
              olmak üzere kuruldu. Sektördeki deneyimli ve uzman kadromuz, müşteri memnuniyetini
              merkeze alarak, sizlere kaliteli gayrimenkul danışmanlığı hizmeti sunmayı
              amaçlamaktadır.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={12} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <MKTypography variant="h4" color="text" pl={2}>
                Vizyonumuz
              </MKTypography>
              <AboutUsOption
                icon="mediation"
                content={
                  <>
                    Vizyonumuz, müşterilerimize değer katacak, kaliteli ve şeffaf bir gayrimenkul
                    danışmanlığı deneyimi sunarak sektörde öncü olmaktır. Yola çıkarken
                    belirlediğimiz bu hedef doğrultusunda, her müşterimizin beklentilerini aşan bir
                    hizmet sunma amacındayız.
                  </>
                }
              />
              <MKTypography variant="h4" color="text" pl={2}>
                Misyonumuz
              </MKTypography>
              <AboutUsOption
                icon="mediation"
                content={
                  <>
                    White Stone Gayrimenkul'un misyonu, müşterilerimizin yaşam tarzlarına ve
                    ihtiyaçlarına uygun mükemmel gayrimenkulleri bulmalarına yardımcı olmaktır. Bu
                    süreçte, güvenilirlik, şeffaflık, ve müşteri memnuniyeti odaklı bir yaklaşım
                    benimseyerek, her adımda müşterilerimizle sağlam bir iletişim kurmayı
                    hedefliyoruz.
                  </>
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutUs;
