// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA12I2haEh2QWp1F7oI0fSEgD_kveXfTug",
  authDomain: "whitestonerealestate-bff2f.firebaseapp.com",
  projectId: "whitestonerealestate-bff2f",
  storageBucket: "whitestonerealestate-bff2f.appspot.com",
  messagingSenderId: "78556658073",
  appId: "1:78556658073:web:5b877bbf2c8dfbf52bfb08",
  measurementId: "G-Q8T0P6EL7E",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
