/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

function Information() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 6 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="insights"
                title="Uzman Ekibimiz"
                description="Sektördeki uzman ve deneyimli kadromuz, gayrimenkul ihtiyaçlarınızı en iyi şekilde anlamak ve çözümler sunmak için burada."
              />
              <SimpleInfoCard
                icon="insights"
                title="Geniş Portföy"
                description="Sizlere geniş bir gayrimenkul portföyü sunarak, farklı beklentilere uygun seçeneklerle karşılaşmanızı sağlıyoruz."
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="insights"
                title="Kişiselleştirilmiş Hizmet"
                description="Her müşteri benzersizdir. İhtiyaçlarınıza özel çözümler sunarak, size özel bir danışmanlık deneyimi yaşatıyoruz."
              />
              <SimpleInfoCard
                icon="insights"
                title="Şeffaflık ve Güvenilirlik"
                description="İşimizin temelinde şeffaflık ve güvenilirlik ilkesi bulunmaktadır. Her aşamada size açık ve doğru bilgiler sunarak güveninizi kazanmayı amaçlıyoruz."
              />
              <SimpleInfoCard
                icon="insights"
                title="Bilgi ve Rehberlik"
                description="Sektördeki güncel gelişmeleri ve gayrimenkul alım-satım süreçlerini anlamanıza yardımcı olacak zengin içerikli blog ve rehberlik kaynaklarına erişim sağlıyoruz."
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack spacing={{ xs: 4, md: 8 }}>
          White Stone Gayrimenkul, sizinle birlikte hayalinizdeki gayrimenkule ulaşmak için burada.
          Bizimle iletişime geçin, size özel çözümlerimizle tanışın ve yeni bir yaşamın kapılarını
          aralayın!
        </Stack>
      </Container>
    </MKBox>
  );
}

export default Information;
