/* eslint-disable react/prop-types */
import React from "react";
import GoogleMapReact from "google-map-react";
import PlaceIcon from "@mui/icons-material/Place";
import "./map.css";
import MKButton from "components/MKButton";

const location = {
  address: "Yeşilova, Green Place, 4014. Cad. 7/1, 06790 Etimesgut/Ankara",
  phone: "05312379049",
  contact: "Hakan Aktaş",
  lat: 39.96297270712741,
  lng: 32.608387015344825,
};

const googleapikey = "AIzaSyCB0t-tNBqqTzAnBSjvCNryg5pYuJiD9ZY";

const LocationPin = ({ loc }) => (
  <div className="pin">
    <PlaceIcon />
    <MKButton fontWeight="bold" href="https://maps.app.goo.gl/bJKpyriMT2RN8g2Q6" target="_blank">
      {loc.address} {loc.contact} {loc.phone}
    </MKButton>
    {/* <MKTypography>{loc.contact}</MKTypography>
    <MKButton startIcon={<LocalPhoneIcon />}>{loc.phone}</MKButton> */}
  </div>
);

const Map = () => (
  <div className="map">
    <h2 className="map-h2">Bizi ziyaret edin</h2>

    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleapikey }}
        defaultCenter={location}
        defaultZoom={17}
      >
        <LocationPin loc={location} />
      </GoogleMapReact>
    </div>
  </div>
);

export default Map;
